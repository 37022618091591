section.blog-section {
    .contact-bar-wrap {
        background: linear-gradient(#384e48 0%, #1e2a26 100%);
        border: 15px solid #fff;
        border-right: none!important;
        padding: 100px 0px 100px 160px;
        @media screen and (max-width: 1600px) {
            & {
                padding: 100px 0px 100px 100px;
            }
        }
        @media screen and (max-width: 991px) {
            & {
                border: none !important;
                padding: 100px 30px;
            }
        }
    }
    .blog-wrap {
        background: linear-gradient(#384e48 0%, #1e2a26 100%);
        border: 15px solid #fff;
        border-right: none!important;
        padding: 70px 0px 70px 170px;

        @media screen and (max-width: 1199px) {
            & {

                padding-left: 100px;
            }
        }
        @media screen and (max-width: 991px) {
            & {

                padding-left: 100px;
            }
        }
        @media screen and (max-width: 767px) {
            & {
                padding: 55px 15px 55px 60px;
            }
        }
        
        @media screen and (max-width: 576px) {
            & {
                padding: 55px 35px;
                margin-left: 4vw;
            }
        } 
    }
    &.has-contact {
        &:not(.has-faqs) {
            padding-top: 300px !important;
            @media screen and (max-width: 1600px) {
                & {
                    padding-top: 600px !important;
                }
            }
            @media screen and (max-width: 991px) {
                & {
                    padding-top: 100px !important;
                }
            }
        }
    }
}
