.w-100 {
width:100% !important;
}

.py-30px {
padding-top:30px !important;padding-bottom:30px !important;
}

@media screen and (min-width: 992px) {.py-lg-40px {
padding-top:40px !important;padding-bottom:40px !important;
}

}
 .m-0 {
margin:0px !important;
}

.p-0 {
padding:0px !important;
}

.font-size-13px {
font-size:13px !important;
}

.m-0 {
margin:0px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.letter-spacing-0 {
letter-spacing:0px !important;
}

.font-size-13px {
font-size:13px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.font-size-13px {
font-size:13px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.p-0 {
padding:0px !important;
}

.py-20px {
padding-top:20px !important;padding-bottom:20px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

}
 .w-100 {
width:100% !important;
}

.font-size-14 {
font-size:14px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.m-0 {
margin:0px !important;
}

.py-5px {
padding-top:5px !important;padding-bottom:5px !important;
}

.h-100 {
height:100% !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

}
 .w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.mb-60px {
margin-bottom:60px !important;
}

@media screen and (min-width: 992px) {.mb-lg-0 {
margin-bottom:0px !important;
}

}
 .mt-n60px {
margin-top:-60px !important;
}

.py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

@media screen and (min-width: 992px) {.py-lg-0 {
padding-top:0px !important;padding-bottom:0px !important;
}

}
 @media screen and (min-width: 992px) {.pl-lg-30px {
padding-left:30px !important;
}

}
 @media screen and (min-width: 1200px) {.pl-xl-50px {
padding-left:50px !important;
}

}
 .mb-0 {
margin-bottom:0px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.pr-30px {
padding-right:30px !important;
}

.pt-15px {
padding-top:15px !important;
}

.py-70px {
padding-top:70px !important;padding-bottom:70px !important;
}

.w-100 {
width:100% !important;
}

@media screen and (min-width: 1200px) {.px-xl-15px {
padding-left:15px !important;padding-right:15px !important;
}

}
 @media screen and (min-width: 768px) {.px-md-5px {
padding-left:5px !important;padding-right:5px !important;
}

}
 .px-15px {
padding-left:15px !important;padding-right:15px !important;
}

.pb-10px {
padding-bottom:10px !important;
}

@media screen and (min-width: 992px) {.pb-lg-0 {
padding-bottom:0px !important;
}

}
 .px-5px {
padding-left:5px !important;padding-right:5px !important;
}

.w-100 {
width:100% !important;
}

.mr-10px {
margin-right:10px !important;
}

@media screen and (min-width: 1200px) {.mr-xl-20px {
margin-right:20px !important;
}

}
 .px-5px {
padding-left:5px !important;padding-right:5px !important;
}

.w-100 {
width:100% !important;
}

@media screen and (min-width: 768px) {.mt-md-0 {
margin-top:0px !important;
}

}
 .mt-10px {
margin-top:10px !important;
}

.pt-10px {
padding-top:10px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.pt-lg-160px {
padding-top:160px !important;
}

}
 .font-size-20px {
font-size:20px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.line-height-34px {
line-height:34px !important;
}

.mb-45px {
margin-bottom:45px !important;
}

.mt-30px {
margin-top:30px !important;
}

.mb-5px {
margin-bottom:5px !important;
}

.font-size-12px {
font-size:12px !important;
}

.font-size-16px {
font-size:16px !important;
}

.mr-10px {
margin-right:10px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.h-100 {
height:100% !important;
}

.font-size-12px {
font-size:12px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.line-height-22px {
line-height:22px !important;
}

.mb-45px {
margin-bottom:45px !important;
}

@media screen and (min-width: 992px) {.mb-lg-65px {
margin-bottom:65px !important;
}

}
 .mb-0 {
margin-bottom:0px !important;
}

.mt-40px {
margin-top:40px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

@media screen and (min-width: 768px) {.mr-md-20px {
margin-right:20px !important;
}

}
 .px-0 {
padding-left:0px !important;padding-right:0px !important;
}

@media screen and (min-width: 768px) {.mt-md-0 {
margin-top:0px !important;
}

}
 .mt-10px {
margin-top:10px !important;
}

.h-100 {
height:100% !important;
}

.font-size-18px {
font-size:18px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.line-height-30px {
line-height:30px !important;
}

.mt-30px {
margin-top:30px !important;
}

.w-100 {
width:100% !important;
}

.mb-70px {
margin-bottom:70px !important;
}

.line-height-32px {
line-height:32px !important;
}

.mt-20px {
margin-top:20px !important;
}

.w-100 {
width:100% !important;
}

.mt-15px {
margin-top:15px !important;
}

@media screen and (min-width: 576px) {.ml-sm-20px {
margin-left:20px !important;
}

}
 .w-100 {
width:100% !important;
}

.mt-15px {
margin-top:15px !important;
}

.pt-100px {
padding-top:100px !important;
}

.mb-n80px {
margin-bottom:-80px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

@media screen and (min-width: 992px) {.pl-lg-80px {
padding-left:80px !important;
}

}
 @media screen and (min-width: 1200px) {.pl-xl-100px {
padding-left:100px !important;
}

}
 @media screen and (min-width: 992px) {.py-lg-70px {
padding-top:70px !important;padding-bottom:70px !important;
}

}
 .font-size-12px {
font-size:12px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.my-20px {
margin-top:20px !important;margin-bottom:20px !important;
}

.my-10px {
margin-top:10px !important;margin-bottom:10px !important;
}

.font-size-25px {
font-size:25px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.font-size-25px {
font-size:25px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.line-height-38px {
line-height:38px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

@media screen and (min-width: 1200px) {.pt-xl-0 {
padding-top:0px !important;
}

}
 .pt-20px {
padding-top:20px !important;
}

.w-100 {
width:100% !important;
}

$theme-colors: map-merge(("F6F6F4":#F6F6F4), $theme-colors);
 .pt-200px {
padding-top:200px !important;
}

.pb-90px {
padding-bottom:90px !important;
}

.pb-50px {
padding-bottom:50px !important;
}

.font-size-18px {
font-size:18px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.py-30px {
padding-top:30px !important;padding-bottom:30px !important;
}

.m-0 {
margin:0px !important;
}

.line-height-32px {
line-height:32px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.font-size-13px {
font-size:13px !important;
}

.mx-10px {
margin-left:10px !important;margin-right:10px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.font-size-13px {
font-size:13px !important;
}

.mx-10px {
margin-left:10px !important;margin-right:10px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.font-size-13px {
font-size:13px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.p-0 {
padding:0px !important;
}

.p-0 {
padding:0px !important;
}

.m-0 {
margin:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.px-4 {
padding-left:4px !important;padding-right:4px !important;
}

.pt-4 {
padding-top:4px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mx-1 {
margin-left:1px !important;margin-right:1px !important;
}

.pt-2 {
padding-top:2px !important;
}

@media screen and (min-width: 768px) {.pt-md-4 {
padding-top:4px !important;
}

}
 .pb-4 {
padding-bottom:4px !important;
}

.px-2 {
padding-left:2px !important;padding-right:2px !important;
}

.ml-4 {
margin-left:4px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.p-0 {
padding:0px !important;
}

